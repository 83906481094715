import React from "react";
import "../scss/btn.scss";
const CustomButton = ({ text, onClick }) => {
	return (
		<button className="btnValentine" onClick={onClick}>
			{text}
		</button>
	);
};

export default CustomButton;
