import React from "react";
import '../scss/App.scss'
const Loadinglove = () => {
	return (
		<div className="loadercontent">
			<div className="alllove">
				<div className="heartcont">
					<span className="love love1 glyphicon glyphicon-heart">♥</span>
					<span className="love love2 glyphicon glyphicon-heart">♥</span>
					<span className="love love3 glyphicon glyphicon-heart">♥</span>
					<span className="love love4 glyphicon glyphicon-heart">♥</span>
					<span className="love love5 glyphicon glyphicon-heart">♥</span>
				</div>
				<h1>Loading Love...</h1>
			</div>
		</div>
	);
};

export default Loadinglove;
