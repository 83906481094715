import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";
import "../scss/bglove.scss";
// import './Background.css'; // Import file CSS cho background

function Background() {
	const navigate = useNavigate();

	const handleButtonClick = () => {
		// Điều hướng sang trang mới
		navigate("/valentine");
	};
	return (
		<div className="image-grid">
			<div className="image-item">
				<img src="image9.webp" alt="HN va HL" />
			</div>
			<div className="image-item">
				<img src="image7.webp" alt="HN va HL" />
			</div>
			<div className="image-item">
				<img src="image3.webp" alt="HN va HL" />
			</div>
			<div className="image-item">
				<img src="image14.webp" alt="HN va HL" />
			</div>
			<div className="image-item">
				<img src="image4.webp" alt="HN va HL" />
			</div>
			<div className="image-item">
				<img src="image8.webp" alt="HN va HL" />
			</div>

			<div className="btn-center">
				<CustomButton text="Bắt đầu 💖" onClick={handleButtonClick} />
			</div>
		</div>
	);
}

export default Background;
