import React from "react";

const AudioPlayer = () => {
	return (
		<audio autoPlay loop>
			<source src="bg.mp3" type="audio/mpeg" />
			Your browser does not support the audio element.
		</audio>
	);
};

export default AudioPlayer;
