import React, { useState, useEffect, useMemo } from "react";
import AudioPlayer from "../components/Audio";
import CustomButton from "../components/CustomButton";
import "../scss/valentine.scss";
import { useNavigate } from "react-router-dom";
import Loadinglove from "../components/Loadinglove";
const loadImageOrVideo = (src) => {
	return new Promise((resolve, reject) => {
		let mediaElement;
		if (src.includes(".webm")) {
			mediaElement = document.createElement("video");
			mediaElement.onloadedmetadata = () => resolve(mediaElement);
		} else {
			mediaElement = new Image();
			mediaElement.onload = () => resolve(mediaElement);
		}
		mediaElement.onerror = reject;

		// Set src
		mediaElement.src = src;
	});
};

const Valentine = () => {
	const navigate = useNavigate();

	const backHome = () => {
		// Điều hướng sang trang mới
		navigate("/");
	};
	const contentdata = useMemo(
		() => [
			{
				type: "video",
				src: "video1.webm",
				title: "Nguyễn Thị Hồng Nguyên",
				message: "Valentine vui vẻ nhaaaaa, yêu emmmm 💝",
			},
			{
				type: "image",
				src: "image3.webp",
				title: "Lần đầu chụp ảnh chungg",
				message: "Lúc này nhìn HN dangiu dễ sợ, anh mê em quá trời lunnn 💋",
			},
			{
				type: "image",
				src: "image14.webp",
				title: "24/10/2023",
				message: "hyyyyyyyyy ♥",
			},
			{
				type: "image",
				src: "image15.webp",
				title: "Hẹn hò roài nèeee",
				message: "Lúc này ngồi kế bên là tim anh đập bịch bịch bịch á 💓",
			},
			{
				type: "image",
				src: "image11.webp",
				title: "Hai đứa iu nhau làm trò khùm đin =))",
				message: "Kế bên em vui quá trời lun, hai người lowkey chụp ảnh cùng nhauu 💞",
			},
			{
				type: "image",
				src: "image12.webp",
				title: "Bánh nèeee",
				message: "Làm bánh tặng HN bữa giáng sinh nèee 🍰",
			},
			{
				type: "image",
				src: "image13.webp",
				title: "Lần đầu đi chơi xa cùng nhau",
				message: "Thưn HN quá trời, đi quá tr xa, cái lưn 2 đứa bấc ỗn 😢",
			},
			{
				type: "image",
				src: "image10.webp",
				title: "Đi cafe cùn nhau làm khùm đin",
				message: "HN đeo kính dangiu quáaaaa 😍",
			},
			{
				type: "image",
				src: "image2.webp",
				title: "Chỗ hẹn hò quen thuộccc",
				message: "Chụp hình chỉ mún cạp HN hoyyyy 😆",
			},
			{
				type: "image",
				src: "last.webp",
				title: "Yêu em ♥",
				message:
					"Chúc em valentine vui vẻ nhaaa, mai anh bay xuống rước em nhéeeee, nhớ nhấn vào nút bên dưới dayyy nèee 👇",
			},
		],
		[]
	);
	const [mediaElement, setMediaElement] = useState(false);
	const [content, setContent] = useState(contentdata[0]);
	useEffect(() => {
		const loadContentData = async () => {
			try {
				await Promise.all(
					contentdata.map(async (item) => {
						const mediaElement = await loadImageOrVideo(item.src);
						return { ...item, mediaElement };
					})
				);
			} catch (error) {
				console.error("Error loading content:", error);
			}
		};

		loadContentData()
			.then((element) => {
				setMediaElement(true);
			})
			.catch((error) => {
				console.error("Error loading media:", error);
			});
	}, [contentdata]);
	const [index, setIndex] = useState(0); // Khởi tạo index với giá trị ban đầu là 0
	const [btntext, setbtntext] = useState("Tiếp tục 💕");

	const handleContinueClick = () => {
		// Kiểm tra nếu index đạt tới cuối mảng
		if (index === contentdata.length - 1) {
			window.open("https://m.me/RuriMeiko", "_blank");
		} else {
			// Nếu chưa đạt cuối mảng, thì tăng index và cập nhật nội dung
			const nextIndex = index + 1;
			if (nextIndex === contentdata.length - 1) {
				setbtntext("Nhắn anh nhé 😘");
			}
			setContent(contentdata[nextIndex]);
			setIndex(nextIndex);
		}
	};

	return (
		<div>
			{!mediaElement ? (
				<Loadinglove />
			) : (
				<div className="bodyvalentine">
					<AudioPlayer />

					{content.type === "image" ? (
						<div className="imagebanner">
							<img className="videobanner" src={content.src} alt="HN và HL 💖" />
						</div>
					) : (
						<div className="imagebanner">
							<video className="videobanner" autoPlay loop muted controls={false}>
								<source src={content.src} type="video/webm" />
								Your browser does not support the video tag.
							</video>
						</div>
					)}
					<div className="textmess">
						<div className="textcontan">
							<h2>{content.title}</h2>
							<p>{content.message}</p>
							<div>
								{index !== contentdata.length - 1 ? (
									<CustomButton text={btntext} onClick={handleContinueClick} />
								) : (
									<div
										style={{
											display: "flex",
											gap: "20px",
											"flex-direction": "column",
										}}
									>
										<CustomButton text={"Về nhà 💟"} onClick={backHome} />
										<CustomButton
											text={btntext}
											onClick={handleContinueClick}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Valentine;
